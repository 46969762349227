<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="my-5 mb-3">コースタイプと各コースの設定</h1>
        <banner-hint>
          コースタイプによってフリーコースや通常コースと言った同じ分数でも料金システムの異なるコース群を区別します。<br />
          コースタイプの中に各分数のコースを入れてください。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-for="(type, typeIndex) in courseTypes"
        :key="type.course_type_id"
      >
        <v-card
          class="py-7 px-10"
          elevation="1"
        >
          <!-- コースタイプ -->
          <v-row no-gutters
            class="pt-5"
          >
            <v-col cols="12">
              <v-form :ref="'form-course-type-' + type.course_type_id">
                <v-row no-gutters>
                  <v-col cols="4">
                    <v-text-field
                      v-model.trim="type.course_type"
                      outlined
                      required
                      persistent-hint
                      hint="例：フリーコース"
                      label="コースタイプ名"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" class="pl-2">
                    <v-text-field
                      v-model="type.description"
                      clear-icon
                      clearable
                      counter="100"
                      label="コースタイプの補足説明（必要であれば）"
                      :rules="[valiRules.max100]"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex justify-end py-0">
                    <v-btn
                      v-if="type.create"
                      depressed small
                      color="accent"
                      @click="createCourseType(type)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateCourseType(type)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteCourseType(typeIndex)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card v-if="!type.courses.length" flat>
                <v-card-text>コースタイプを登録したら↓コースを追加してください。</v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- コース -->
          <v-row
            v-for="(course, index) in type.courses"
            :key="course.course_id"
          >
            <v-col cols="12">
              <v-form :ref="'form-course-' + course.course_id">
                <v-row no-gutters>
                  <v-col cols="4"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="course.course_name"
                      required
                      hint="例：60分"
                      label="コース名"
                      :rules="[valiRules.required, valiRules.max20]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="course.course_mins"
                      type="number"
                      required
                      label="コースの長さ（分）"
                      :rules="[valiRules.required, valiRules.minutes]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="course.course_charge"
                      type="number"
                      required
                      label="コース料金（円）"
                      :rules="[valiRules.required, valiRules.price]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      class="pt-2"
                      counter="50"
                      v-model="course.description"
                      :rules="[valiRules.max50]"
                      label="コースの補足説明（必要であれば）"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    class="d-flex justify-end align-center pt-0"
                  >
                    <v-btn
                      v-if="course.create"
                      depressed small
                      color="accent"
                      @click="createCourse(course)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateCourse(course)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteCourse(index, typeIndex)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <!-- 要素追加ボタン -->
          <v-row>
            <v-col>
              <v-btn
                :disabled="type.create"
                depressed
                color="primary"
                @click="addBlankCourse(typeIndex)"
              >コースを追加</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
          depressed
          color="primary"
          @click="addBlankCourseType"
        >コースタイプを追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!courseTypes.length" flat>
          <v-card-text>コースタイプが登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxCourseTypeId: 0,
      maxCourseId: 0,
      courses: [],
      courseTypes: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      publicApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => {
      if (CheckTokenError(error)) {
        this.$emit('reset')
      } else {
        alert($literals.MESSAGE.failedApiGet + error.response.data + error.response.status)
      }
    })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getCourses(),
      ])

      this.loading = false
    },

    //新コース追加
    addBlankCourse(typeIndex) {
      if (this.courseTypes[typeIndex].courses.length >= 10) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.courseTypes[typeIndex].courses.push({
        course_id: ++this.maxCourseId,
        course_name: '',
        course_type_id: this.courseTypes[typeIndex].course_type_id,
        course_mins: null,
        course_charge: null,
        description: '',
        create: true
      })
    },

    //新コースタイプ追加
    addBlankCourseType() {
      if (this.courseTypes.length >= 8) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.courseTypes.push({
        course_type_id: ++this.maxCourseTypeId,
        course_type: '',
        description: '',
        courses: [],
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: コースタイプ取得
    //**************************************************
    getCourses() {
      return this.publicApi.getReqSitePublic('course-type/').then( types => {
        if (!types || !types.length) return

        //id最大値の取得
        this.maxCourseTypeId = Math.max(...types.map( row => parseInt(row.course_type_id) ))

        types.map( type => {
          type.courses = []
          this.courseTypes.push(type)
        })

        //コースを取得
        this.publicApi.getReqSitePublic('course/').then( courses => {
          if (!courses || !courses.length) return

          this.maxCourseId = Math.max(...courses.map( row => parseInt(row.course_id) ))

          //各タイプに該当コースを挿入
          this.courseTypes.map( type => {
            const typeCourses = courses.filter( course => course.course_type_id === type.course_type_id && course.course_mins > 0 )
            type.courses = [...typeCourses]
          })
        })
      })
    },

    //**************************************************
    //コースタイプ新規登録
    //**************************************************
    createCourseType(courseType) {
      if (!this.$refs['form-course-type-' + courseType.course_type_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("course_type", courseType.course_type);
      formData.append("description", courseType.description);

      this.adminApi.apiReqWithData('POST', 'create/course-type/', formData).then( response => {
        courseType.course_type_id = response.course_type_id
        courseType.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //コースタイプ更新
    //**************************************************
    updateCourseType(courseType) {
      if (!this.$refs['form-course-type-' + courseType.course_type_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/course-type/' + courseType.course_type_id
      const formData = new FormData();
      formData.append("course_type", courseType.course_type);
      formData.append("description", courseType.description);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //コースタイプ削除
    //**************************************************
    deleteCourseType(index) {
      const courseType = this.courseTypes[index]
      if (courseType.create) {
        this.courseTypes.splice(index, 1)
        return
      }

      const apiPath = 'delete/course-type/' + courseType.course_type_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.courseTypes.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //コース新規登録
    //**************************************************
    createCourse(course) {
      if (!this.$refs['form-course-' + course.course_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("course_name", course.course_name);
      formData.append("course_type_id", course.course_type_id);
      formData.append("course_mins", course.course_mins);
      formData.append("course_charge", course.course_charge);
      formData.append("description", course.description);

      this.adminApi.apiReqWithData('POST', 'create/course/', formData).then( response => {
        course.course_id = response.course_id
        course.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //コース更新
    //**************************************************
    updateCourse(course) {
      if (!this.$refs['form-course-' + course.course_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/course/' + course.course_id
      const formData = new FormData();
      formData.append("course_name", course.course_name);
      formData.append("course_type_id", course.course_type_id);
      formData.append("course_mins", course.course_mins);
      formData.append("course_charge", course.course_charge);
      formData.append("description", course.description);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //コース削除
    //**************************************************
    deleteCourse(index, typeIndex) {
      const course = this.courseTypes[typeIndex].courses[index]
      if (course.create) {
        this.courseTypes[typeIndex].courses.splice(index, 1)
        return
      }

      const apiPath = 'delete/course/' + course.course_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.courseTypes[typeIndex].courses.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
